import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <header id="header-part">
            <div className="header-top d-none d-lg-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="header-contact">
                                <ul>
                                    <li><i className="fa fa-envelope"></i><a href="mailto:hello@ilccenter.com">hello@ilccenter.com</a></li>
                                    <li><i className="fa fa-phone"></i><span><a href="tel:+903122650456">+90 312 265 04 56</a></span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="header-right d-flex justify-content-end">
                                <div className="social d-flex">
                                    <span className="follow-us">Follow Us :</span>
                                    <ul>
                                        {/* <li><Link><i className="fa fa-facebook-f"></i></Link></li>
                                        <li><Link><i className="fa fa-youtube"></i></Link></li>
                                        <li><Link><i className="fa fa-twitter"></i></Link></li> */}
                                        <li><a href='https://www.instagram.com/genixo.global/?igshid=NmZiMzY2Mjc%3D' target="_blank"><i className="fa fa-instagram"></i></a></li>
                                        <li><a href='https://www.linkedin.com/company/78457108/admin/' target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                                <div className="login-register">
                                    <ul>
                                        <li><Link to="/">Login</Link></li>
                                        {/* <li><Link to="/">Register</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <Link className="navbar-brand" to="/">
                                    <img src="images/logo-ilc.png" style={{height:"120px"}} alt="Logo" />
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link className="active" to="/">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="#">Exams</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/preschool">For Preschool</Link></li>
                                                <li><Link to="/school">For School</Link></li>
                                                <li><Link to="/business">For Business</Link></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/cerf-infos">CERF</Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to="/blogs">Blog</Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="right-icon text-right">
                                    <ul>
                                        <li><a href="javascript:void(0)" id="search"><i className="fa fa-search"></i></a></li>
                                        <li><a href="#"><i className="fa fa-shopping-bag"></i><span>0</span></a></li>
                                    </ul>
                                </div> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}
