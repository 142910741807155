import React from 'react'
import { Link } from 'react-router-dom'

export default function News() {
    return (
        <section id="news-part" className="pt-115 pb-110">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title pb-50">
                            {/* <h5>Latest News</h5> */}
                            <h2>BUSINESS ENGLISH</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="single-news mt-30">
                            <div className="news-thum pb-25">
                                <img src="images/news/n-1.jpg" alt="News" />
                            </div>
                            <div className="news-cont">
                                <ul>
                                    {/* <li><Link href="#"><i className="fa fa-calendar"></i>2 December 2018 </Link></li> */}
                                    {/* <li><Link href="#"> <span>By</span> Adam linn</Link></li> */}
                                </ul>
                                {/* <Link to="/news"><h3>Tips to grade high cgpa in university life</h3></Link> */}
                                <p>You can demonstrate to employers that you have a solid command of English for everyday, practical usage in business by earning a B1/B2 qualification. Each exam's material is focused on real-world job and professional tasks and is intended to help you improve your business English abilities. These qualifications help you in a way that employers can see your level of language proficiency in terms of conducting worldwide business.
</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-news news-list">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="news-thum mt-30">
                                        <img src="images/news/ns-1.jpg" alt="News" />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="news-cont mt-30">
                                        {/* <ul>
                                            <li><Link><i className="fa fa-calendar"></i>2 December 2018 </Link></li>
                                            <li><Link> <span>By</span> Adam linn</Link></li>
                                        </ul> */}
                                        <Link to="/news"><h3>ILC BUSINESS B1</h3></Link>
                                        <p>For business who have mastered the fundamentals of English and now possess useful language skills for daily use, this is an intermediate-level qualification.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-news news-list">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="news-thum mt-30">
                                        <img src="images/news/ns-2.jpg" alt="News" />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="news-cont mt-30">
                                        {/* <ul>
                                            <li><Link><i className="fa fa-calendar"></i>2 December 2018 </Link></li>
                                            <li><Link> <span>By</span> Adam linn</Link></li>
                                        </ul> */}
                                        <Link to="/news"><h3>ILC BUSINESS B2</h3></Link>
                                        <p>This certification demonstrates that business have the language proficiency required to interact comfortably in an English-speaking context and is a wonderful method for them to get ready for higher level exams.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-news news-list">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="news-thum mt-30">
                                        <img src="images/news/ns-3.jpg" alt="News" />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="news-cont mt-30">
                                        {/* <ul>
                                            <li><Link href="#"><i className="fa fa-calendar"></i>2 December 2018 </Link></li>
                                            <li><Link href="#"> <span>By</span> Adam Linn</Link></li>
                                        </ul> */}
                                        <Link to="/news"><h3>ILC BUSINESS C1</h3></Link>
                                        <p>This is evidence of very high English achievement and the perfect certification for students getting ready for college or the workforce.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
