import React from 'react'

export default function Category() {
    return (
        <section id="category-part">
            <div className="container">
                <div className="category pt-40 pb-80">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="category-text pt-40">
                                <h2>Best platform to assess everything</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2">
                            <div className="row category-slide mt-40">
                                <div className="col-lg-4">
                                    <div>
                                        <span className="single-category text-center color-1">
                                            <span className="icon">
                                                <img src="images/all-icon/ctg-1.png" alt="Icon" />
                                            </span>
                                            <span className="cont">
                                                <span>Language</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <span className="single-category text-center color-2">
                                            <span className="icon">
                                                <img src="images/all-icon/ctg-2.png" alt="Icon" />
                                            </span>
                                            <span className="cont">
                                                <span>Business</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <span className="single-category text-center color-3">
                                            <span className="icon">
                                                <img src="images/all-icon/ctg-3.png" alt="Icon" />
                                            </span>
                                            <span className="cont">
                                                <span>School</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-lg-12 pt-3 w-100">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/OdfJ9m0wYv0"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                              
                                </div>




                               
                                {/* <div className="col-lg-4">
                                    <a href="#">
                                        <span className="single-category text-center color-1">
                                            <span className="icon">
                                                <img src="images/all-icon/ctg-1.png" alt="Icon" />
                                            </span>
                                            <span className="cont">
                                                <span>Language</span>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                                <div className="col-lg-4">
                                    <a href="#">
                                        <span className="single-category text-center color-2">
                                            <span className="icon">
                                                <img src="images/all-icon/ctg-2.png" alt="Icon" />
                                            </span>
                                            <span className="cont">
                                                <span>Business</span>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                                <div className="col-lg-4">
                                    <a href="#">
                                        <span className="single-category text-center color-3">
                                            <span className="icon">
                                                <img src="images/all-icon/ctg-3.png" alt="Icon" />
                                            </span>
                                            <span className="cont">
                                                <span>Literature</span>
                                            </span>
                                        </span>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
