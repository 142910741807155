import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer id="footer-part">
            <div className="footer-top pt-40 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about mt-40">
                                <div className="logo">
                                    <Link to="/#"><img src="images/logo-2.png" alt="Logo" /></Link>
                                </div>
                                <p>ILC is a certified exam center for English language assessment. We offer a variety of exams for preschool, young learners, high school, university students and professionals. All exams are designed according to CEFR (Common European Framework of Reference).                                </p>
                                <ul className="mt-20">
                                    {/* <li><Link><i className="fa fa-facebook-f"></i></Link></li>
                                    <li><Link><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link><i className="fa fa-google-plus"></i></Link></li> */}
                                    <li><a href='https://www.instagram.com/genixo.global/?igshid=NmZiMzY2Mjc%3D' target="_blank"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-link mt-40">
                                <div className="footer-title pb-25">
                                    <h6>Exams</h6>
                                </div>
                                <ul>
                                    <li><Link to="/school"><i className="fa fa-angle-right"></i> For School</Link></li>
                                    <li><Link to="/business"><i className="fa fa-angle-right"></i> For Business</Link></li>
                                    <li><Link to="/preschool"><i className="fa fa-angle-right"></i> For Preschool</Link></li>
                                </ul>
                               
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="footer-link support mt-40">
                                <div className="footer-title pb-25">
                                    <h6>Support</h6>
                                </div>
                                <ul>
                                    {/* <li><Link to="/#"><i className="fa fa-angle-right"></i>FAQS</Link></li>
                                    <li><Link to="/#"><i className="fa fa-angle-right"></i>Privacy</Link></li>
                                    <li><Link to="/#"><i className="fa fa-angle-right"></i>Policy</Link></li> */}
                                    <li><Link to="/contact"><i className="fa fa-angle-right"></i>Support</Link></li>
                                    {/* <li><Link to="/#"><i className="fa fa-angle-right"></i>Documentation</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-address mt-40">
                                <div className="footer-title pb-25">
                                    <h6>Contact Us</h6>
                                </div>
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-home"></i>
                                        </div>
                                        <div className="cont">
                                            <p>Bilkent Cyberpark H Blok No:8 Ankara</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="cont">
                                            <p><a href="tel:+903122650456">+90 312 265 04 56</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-envelope-o"></i>
                                        </div>
                                        <div className="cont">
                                            <p>  <a href="mailto:hello@ilccenter.com">hello@ilccenter.com</a></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-copyright pt-10 pb-25">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="copyright text-md-left text-center pt-15">
                                <p>&copy; Copyrights 2022 Genixo All rights reserved. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="copyright text-md-right text-center pt-15">
                                <p>Designed by <span><a href="https://genixo.ai" target="_blank">Genixo</a></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
