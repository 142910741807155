import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import React from 'react';
import MainPage from './pages/main/MainPage';
import ExamPage from "./pages/exam/ExamPage";
import CerfPage from "./pages/cerf/CerfPage";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";
import NewsPage from "./pages/news/NewsPage";
import EventPage from "./pages/event/EventPage";
import CertificatePage from "./pages/certificate/CertificatePage"
import BlogListPage from "./pages/blog/BlogListPage"
import DetectOs from "./pages/DetectOs";
import ExamForBusiness from "./pages/exam/ExamForBusiness";
import ExamForPreschool from "./pages/exam/ExamForPreschool";
import ExamForSchool from "./pages/exam/ExamForSchool";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/confidentiality-agreement"
          element={<MainPage />}
        />
        <Route exact path="/cerf-infos" element={<CerfPage />} />
        <Route exact path="/cerf-info/:id" element={<CerfPage />} />
        <Route exact path="/exams" element={<ExamPage />} />
       
        <Route exact path="/news" element={<NewsPage />} />
        <Route exact path="/new/:id" element={<NewsPage />} />
        <Route exact path="/events" element={<EventPage />} />

        <Route exact path="/business" element={<ExamForBusiness />} />
        <Route exact path="/preschool" element={<ExamForPreschool />} />
        <Route exact path="/school" element={<ExamForSchool />} />
        <Route exact path="/exam/:id" element={<ExamPage />} />

        <Route exact path="/event/:id" element={<EventPage />} />
        <Route exact path="/certificates" element={<CertificatePage />} />
        <Route exact path="/certificate/:id" element={<CertificatePage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/os" element={<DetectOs />} />

        <Route exact path="/blogs" element={<BlogListPage />} />
        <Route exact path="/blog/:id" element={<BlogListPage />} />


        <Route exact path="/" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
