import React from 'react'
import { Link } from 'react-router-dom'

export default function ApplyAprt() {
    return (
        <section id="apply-aprt" className="pb-120">
            <div className="container">
                <div className="apply">
                    <div className="row no-gutters apply-cont apply-color-1">
                        <div className="col-lg-6">
                            <div className="apply-cont apply-color-1">
                                <h3>Associate Member of ALTE</h3>
                                <p>ALTE is an association of language test providers who work together to promote the fair and accurate assessment of linguistic ability across Europe and beyond.  ALTE organises training and conferences on aspects of language assessment, and operates a quality auditing system of European language examinations.</p>
                                {/* <Link to="/about" className="main-btn">Read More</Link> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="apply-cont apply-color-2">
                                <h3>Partnerships</h3>
                                <p>We are carrying out structuring studies in many countries. We are open to partnership applications from countries.</p>
                                <Link to="/about" className="mt-4 main-btn">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
