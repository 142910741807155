import React from 'react'
import { Link } from 'react-router-dom'

export default function About() {
    return (
        <section id="about-part" className="pt-65">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title mt-50">
                            {/* <h5>ILC</h5> */}
                            <h2>Welcome to ILC </h2>
                        </div>
                        <div className="about-cont">
                            <p>ILC exams are comprehensive exams that increase the pleasure, effectiveness and rewards of learning English.
Our exams are supported by efficient teaching and learning activities. They inspire individuals of all ages and skill levels to learn English and gain useful real-world skills. The Common European Framework of Reference (CEFR) is the foundation of every exam, helping students progressively improve their speaking, writing, reading and listening skills.</p>
                            {/* <Link className="main-btn mt-55" to="/about">Learn More</Link> */}
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <div className="about-event mt-50">
                            <div className="event-title">
                                <h3>Upcoming ILC Events</h3>
                            </div>
                            <ul>
                                <li>
                                    <div className="single-event">
                                        <span><i className="fa fa-calendar"></i> 2 February 2023</span>
                                        <Link to="/events"><h4>Language Teaching Methods</h4></Link>
                                        <span><i className="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span>
                                        <span><i className="fa fa-map-marker"></i> Online</span>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="single-event">
                                        <span><i className="fa fa-calendar"></i> 4 March 2023</span>
                                        <Link to="/events"><h4>Awareness Training</h4></Link>
                                        <span><i className="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span>
                                        <span><i className="fa fa-map-marker"></i> Online</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="single-event">
                                        <span><i className="fa fa-calendar"></i> 12 April 2023</span>
                                        <Link to="/events"><h4>International Exams</h4></Link>
                                        <span><i className="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span>
                                        <span><i className="fa fa-map-marker"></i> Online</span>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-bg">
                <img src="images/about/bg-1.png" alt="About" />
            </div>
        </section>
    )
}
