import React from 'react'
import { Link } from "react-router-dom";


export default function ExamTypePart(props) {
    return (
        <div className="col-lg-4 mt-3">
            <div className="single-course">
                <div className="thum">
                    <div className="image">
                        <img src={props.image} alt="Course" />
                    </div>
                    <div className="price">
                        <span>{props.applicationType}</span>
                    </div>
                </div>
                <div className="cont">
                    {/* <ul>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                        <li><i className="fa fa-star"></i></li>
                    </ul> */}
                    {/* <span>({props.applicationCount} Application)</span> */}
                    {/* <Link to={`exam/${props.code}`}> */}
                        <h4 className='pt-3 pb-3'>{props.typeName}</h4>
                        {/* </Link> */}
                    <p className='pb-2'>{props.shortExplain}</p>
                    {/* <div className="course-teacher p-0"> */}
                        {/* <div className="thum">
                            <a href="#"><img src={props.logo} alt="teacher" /></a>
                        </div>
                        <div className="name">
                            <a href="#"><h6>Mark anthem</h6></a>
                        </div> */}
                        {/* <Link to={`exam/${props.code}`} className='btn btn-warning mt-2'>Details</Link> */}
                        {/* <div className="admin"> */}
                            {/* <ul>
                                <li><Link><i className="fa fa-user"></i><span>{props.userCount}</span></Link></li>
                                <li><Link><i className="fa fa-heart"></i><span>{props.heartCount}</span></Link></li>
                            </ul> */}
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}
