import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Preloader from '../components/Preloader'
import About from './components/About'
import ApplyAprt from './components/ApplyAprt'
import Category from './components/Category'
import ExamType from './components/ExamType'
import News from './components/News'
import PartnarLogo from './components/PartnarLogo'
import Publication from './components/Publication'
import SearchBox from './components/SearchBox'
import Slider from './components/Slider'
import Testimonial from './components/Testimonial'
import VideoFeature from './components/VideoFeature'

export default function MainPage() {
  return (
    <React.Fragment>
      {/* <Preloader/> */}
      <Header/>
      <SearchBox/>
      <Slider/>
      <Category/>
      <About/>
      <ApplyAprt/>
      <ExamType/>
      <VideoFeature/>
      <Publication/>
      <Testimonial />
      <News />
      {/* <PartnarLogo /> */}
      <Footer />
    </React.Fragment>
  )
}
