import React from 'react'
import { Link } from 'react-router-dom'

export default function Publication() {
    return (
        <section id="publication-part" className="pt-115 pb-120 gray-bg">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-8 col-sm-7">
                        <div className="section-title pb-60">
                            <h5>Certificates</h5>
                            <h2>From Certificates </h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-5">
                        <div className="products-btn text-right pb-60">
                            {/* <Link to="/certificates" className="main-btn">All Certificates</Link> */}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-publication mt-30 text-center">
                            <div className="image">
                                <img src="images/publication/p-1.png" alt="Publication" />
                                {/* <div className="add-cart">
                                    <ul>
                                        <li><Link><i className="fa fa-shopping-cart"></i></Link></li>
                                        <li><Link><i className="fa fa-heart-o"></i></Link></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="content pt-10">
                                <h5 className="book-title"><Link>Sample Certificate </Link></h5>
                                <p className="writer-name"><span>By</span> ILC</p>
                                {/* <div className="price-btn d-flex align-items-center justify-content-between">
                                    <div className="price pt-20">
                                        <span className="discount-price">$250</span>
                                        <span className="normal-price">$200</span>
                                    </div>
                                    <div className="button pt-10">
                                        <Link to="/certificates" className="main-btn"><i className="fa fa-cart-plus"></i> Read More</Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-publication mt-30 text-center">
                            <div className="image">
                                <img src="images/publication/p-2.png" alt="Publication" />
                                <div className="add-cart">
                                    <ul>
                                        <li><Link><i className="fa fa-shopping-cart"></i></Link></li>
                                        <li><Link><i className="fa fa-heart-o"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content pt-10">
                                <h5 className="book-title"><Link>Student Certificate </Link></h5>
                                <p className="writer-name"><span>By</span> ILC KAT</p>
                                <div className="price-btn d-flex align-items-center justify-content-between">
                                    <div className="price pt-20">
                                        <span className="discount-price">$250</span>
                                        <span className="normal-price">$200</span>
                                    </div>
                                    <div className="button pt-10">
                                        <Link to="/certificates" className="main-btn"><i className="fa fa-cart-plus"></i> Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-publication mt-30 text-center">
                            <div className="image">
                                <img src="images/publication/p-3.png" alt="Publication" />
                                <div className="add-cart">
                                    <ul>
                                        <li><Link><i className="fa fa-shopping-cart"></i></Link></li>
                                        <li><Link><i className="fa fa-heart-o"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content pt-10">
                                <h5 className="book-title"><Link>Bussiniss Certificate </Link></h5>
                                <p className="writer-name"><span>By</span> ILC BIS</p>
                                <div className="price-btn d-flex align-items-center justify-content-between">
                                    <div className="price pt-20">
                                        <span className="discount-price">$250</span>
                                        <span className="normal-price">$200</span>
                                    </div>
                                    <div className="button pt-10">
                                        <Link to="/certificates" className="main-btn"><i className="fa fa-cart-plus"></i> Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-publication mt-30 text-center">
                            <div className="image">
                                <img src="images/publication/p-4.png" alt="Publication" />
                                <div className="add-cart">
                                    <ul>
                                        <li><Link><i className="fa fa-shopping-cart"></i></Link></li>
                                        <li><Link><i className="fa fa-heart-o"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content pt-10">
                                <h5 className="book-title"><Link>Collage Certificate </Link></h5>
                                <p className="writer-name"><span>By</span> ILC ADV</p>
                                <div className="price-btn d-flex align-items-center justify-content-between">
                                    <div className="price pt-20">
                                        <span className="discount-price">$250</span>
                                        <span className="normal-price">$200</span>
                                    </div>
                                    <div className="button pt-10">
                                        <Link to="/certificates" className="main-btn"><i className="fa fa-cart-plus"></i> Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
        
    )
}
