import React from 'react'
import ExamTypePart from './parts/ExamTypePart'

const ExamListData = require('../../../data/ExamList.json')

export default function ExamType() {

    return (
        <section id="course-part" className="pt-115 pb-120 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title pb-45">
                            <h5>PROFICIENCY EXAMS</h5>
                            <h2>ILC FOR SCHOOL</h2>
                            <p>
                            We offer exams for different levels of proficiency based on CEFR (Common European Framework of Reference) which is an commonly accepted language assessment standart all over the world.  
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row course-slide mt-30">
                    {
                        ExamListData.map((examType, key) =>
                            <ExamTypePart code={examType.code}
                                typeName={examType.typeName}
                                id={examType.id}
                                applicationType={examType.applicationType}
                                logo={examType.logo}
                                image={examType.image}
                                userCount={examType.userCount}
                                heartCount={examType.heartCount}
                                applicationCount={examType.applicationCount}
                                shortExplain={examType.shortExplain} />
                        )
                    }
                </div>
            </div>
        </section>
    )
}
