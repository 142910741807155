import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SearchBox from '../main/components/SearchBox'
import Testimonial from '../main/components/Testimonial'

export default function ExamForPreschool() {
  return (
    <React.Fragment>
    <Header />
    <SearchBox />
    <Testimonial/>
    <Footer />
  </React.Fragment>
  )
}
