import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SearchBox from '../main/components/SearchBox'
import VideoFeature from '../main/components/VideoFeature'


export default function CerfPage() {
  return (
    <React.Fragment>
    <Header />
    <SearchBox />
    <VideoFeature/>
    <Footer />
  </React.Fragment>
  )
}
