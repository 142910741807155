import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SearchBox from '../main/components/SearchBox'


export default function ExamPage() {
  return (
    <React.Fragment>
    <Header />
    <SearchBox />
    <div> Main Contbbbent</div>
    <Footer />
  </React.Fragment>
  )
}
