import React from 'react'

export default function VideoFeature() {
    return (
        <section id="video-feature" className="bg_cover pt-60 pb-110" style={{ backgroundImage: "url(images/bg-1.jpg)" }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-last order-lg-first">
                        <div className="video text-lg-left text-center pt-50">
                            <a className="Video-popup" href="https://www.youtube.com/watch?v=UAehOcVfr3Y"><i className="fa fa-play"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 order-first order-lg-last">
                        <div className="feature pt-50">
                            <div className="feature-title">
                                <h3>Common Reference Levels</h3>
                            </div>
                            <ul>
                                <li>
                                    <div className="single-feature">
                                        <div className="icon">
                                            <img src="images/all-icon/f-1.png" alt="icon" />
                                        </div>
                                        <div className="cont">
                                            <h4>Global scale</h4>
                                            <p>It is desirable that the common reference points are presented in different ways for different purposes. For some purposes it will however be appropriate to summarise the set of proposed Common Reference Levels in a holistic summarized table.</p>
                                            {/* <p><Link to="/cerf-infos" className="main-btn">Read More</Link></p> */}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="single-feature">
                                        <div className="icon">
                                            <img src="images/all-icon/f-2.png" alt="icon" />
                                        </div>
                                        <div className="cont">
                                            <h4>Self-assessment grid</h4>
                                            <p>In order to orient learners, teachers and other users within the educational system for some practical purpose, a more detailed overview is necessary.</p>
                                            {/* <p><Link to="/cerf-infos" className="main-btn">Read More</Link></p> */}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="single-feature">
                                        <div className="icon">
                                            <img src="images/all-icon/f-3.png" alt="icon" />
                                        </div>
                                        <div className="cont">
                                            <h4>Qualitative aspects of spoken language use</h4>
                                            <p>The chart in this table was designed to assess spoken performances. It focuses on different qualitative aspects of language use.</p>
                                            {/* <p><Link to="/cerf-infos" className="main-btn">Read More</Link></p> */}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="feature-bg"></div>
        </section>
    )
}
