import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SearchBox from '../main/components/SearchBox'


export default function CertificatePage() {
  return (
    <React.Fragment>
      <Header />
      <SearchBox />
      <div> Main Content</div>
      <Footer />
    </React.Fragment>
  )
}
